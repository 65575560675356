import { useState } from "react";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TaskItem from './TaskItem';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";


const TaskWidget = ({ activeTasks, totalActiveTasks, updateTaskOrder, addTask, deleteTask, toggleTaskComplete, toggleTaskFocus, toggleTaskFrog }) => {

    const onDragEnd = async (dragResult) => {
        const { source, destination } = dragResult;
        if (!destination || source.index === destination.index) return;
        const reorderedTasks = Array.from(activeTasks);
        const [reorderedTask] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, reorderedTask);
        updateTaskOrder(reorderedTasks);
    };

    const [taskText, setTaskText] = useState("");
    const handleAddTask = async (e) => {
        console.log("in handleAddTask");
        console.log("taskText", taskText);
        e.preventDefault();
        if (!taskText.trim()) return;
        await addTask(taskText);
        setTaskText("");
    };

    return (
        <div className="col-md-8 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold bg-pr-clr">
                    Tasks
                </div>
                <div className="flex-grow-1">
                    <div className="row py-2">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <form onSubmit={handleAddTask} className="">
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        required
                                        type="text"
                                        placeholder="Add new to-do item..."
                                        value={taskText}
                                        onChange={(e) => setTaskText(e.target.value)}
                                        style={{ borderRadius: '5px' }}
                                    />
                                    <button type="submit" className="btn btn-link text-success border p-1 m-1">
                                        <FontAwesomeIcon icon={faCirclePlus} />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row justify-content-center flex-grow-1">
                        <div className="col px-3">
                            {activeTasks.length === 0 ? (
                                <div className="text-center p-3">
                                    <p>No Active Tasks! Add some above</p>
                                </div>
                            ) : (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="tasks">
                                        {provided => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-wrap">
                                                {activeTasks.map((task, index) => (
                                                    <div key={task._id} className="col-md-6 col-sm-12 p-1">
                                                        <TaskItem
                                                            index={index}
                                                            task={task}
                                                            deleteTask={deleteTask}
                                                            toggleTaskFocus={toggleTaskFocus}
                                                            toggleTaskFrog={toggleTaskFrog}
                                                            toggleTaskComplete={toggleTaskComplete}
                                                        />
                                                    </div>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}
                        </div>
                    </div>
                </div>
                <div className="p-2 mt-auto bg-pr-clr">
                    {totalActiveTasks} Active Tasks
                </div>
            </div>
        </div >
    );
};

export default TaskWidget;
