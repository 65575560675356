import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const GoalModal = ({
    show,
    onClose,
    goal,
    goalEntity,
    goalStartDate,
    goalDeadline,
    goalAssessment,
    setGoal,
    setGoalStartDate,
    setGoalDeadline,
    setGoalAssessment,
    assessGoal,
    setGoalEntity,
    createEntityComponent,
    saveEntity,
    loading,
    setLoading,
    handleGoalChange,
}) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        assessGoal(e);
    };

    const saveGoal = async () => {
        if (!goal) {
            alert("Goal cannot be empty!");
            return;
        }

        try {
            await saveEntity("goal", goal, 0);
            setGoalEntity([createEntityComponent(0, "goal", goal, true)]);
        } catch (error) {
            console.error("Error saving goal:", error);
        }
    };

    const handleSaveAndClose = async () => {
        await saveGoal();
        onClose();
    };

    return (
        <Modal show={show} onHide={onClose} size="xl" className="rounded">
            <Modal.Header closeButton className="bg-pr-clr-color-color-color-color-color-color-color-color-color-color-color-color-color text-secondary rounded-top">
                <Modal.Title className="fw-bold">Goal Assessment</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                <div className="row mb-4">
                    <div className="col">
                        <div className="card shadow-sm">
                            <div className="card-header bg-pr-clr text-secondary">
                                <h5 className="card-title mb-0">Goal Details</h5>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="goalInput" className="form-label fw-bold">
                                                Goal
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="goalInput"
                                                placeholder="Enter your goal"
                                                value={goal}
                                                onChange={handleGoalChange}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="goalStartDate" className="form-label fw-bold">
                                                Start Date
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="goalStartDate"
                                                value={goalStartDate}
                                                onChange={(e) => setGoalStartDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label htmlFor="goalDeadline" className="form-label fw-bold">
                                                Deadline
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="goalDeadline"
                                                value={goalDeadline}
                                                onChange={(e) => setGoalDeadline(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button type="submit" className="btn btn-primary-custom">
                                            Assess Goal
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="text-center p-3">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <div className="row row-cols-1 g-3">
                        {goalAssessment.feedback && (
                            <div className="col-md-6 col-sm-12">
                                <div className="card shadow-sm h-100">
                                    <div className="card-header bg-pr-clr text-secondary">
                                        <h5 className="card-title mb-0">Feedback</h5>
                                    </div>
                                    <div className="card-body" style={{ lineHeight: "1.50" }}>
                                        <p className="card-text mb-3">
                                            {goalAssessment.feedback.positive}
                                        </p>
                                        <p className="card-text mb-3">
                                            <strong>Difficulty:</strong> {goalAssessment.feedback.difficulty}
                                        </p>
                                        <p className="card-text mb-0">
                                            <strong>SMART Suggestions:</strong> {goalAssessment.feedback.suggestions}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {goalAssessment.ratings && (
                            <div className="col-md-6 col-sm-12">
                                <div className="card shadow-sm h-100">
                                    <div className="card-header bg-pr-clr text-secondary">
                                        <h5 className="card-title mb-0">SMART Ratings</h5>
                                    </div>
                                    <div className="card-body" style={{ lineHeight: "1.50" }}>
                                        {goalAssessment.ratings.map((rating, index) => (
                                            <div key={index} className="mb-2">{rating}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        {goalAssessment.strategies && goalAssessment.strategies.length > 0 && (
                            <div className="col">
                                <div className="card shadow-sm h-100">
                                    <div className="card-header bg-pr-clr text-secondary">
                                        <h5 className="card-title mb-0">Enhanced Strategy</h5>
                                    </div>
                                    <div className="card-body">
                                        {goalAssessment.strategies.map((strategy, index) => (
                                            <div key={index} className="row py-3 border-bottom">
                                                <div className="col-8">
                                                    <span className="text-wrap">{strategy}</span>
                                                </div>
                                                <div className="col-4 d-flex justify-content-center align-items-center">
                                                    <div
                                                        className="btn btn-primary-custom"
                                                        onClick={() => setGoal(strategy)}
                                                    >
                                                        Swap Goal
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}

                        {goalAssessment.smartGoals && goalAssessment.smartGoals.length > 0 && (
                            <div className="col">
                                <div className="card shadow-sm h-100">
                                    <div className="card-header bg-pr-clr text-secondary">
                                        <h5 className="card-title mb-0">Enhanced SMART Features</h5>
                                    </div>
                                    <div className="card-body">
                                        {goalAssessment.smartGoals.map((smartGoal, index) => (
                                            <div key={index} className="row py-3 border-bottom">
                                                <div className="col-8">
                                                    <span className="text-wrap">{smartGoal}</span>
                                                </div>
                                                <div className="col-4 d-flex justify-content-center align-items-center">
                                                    <div
                                                        size="sm"
                                                        className="btn btn-primary-custom"
                                                        onClick={() => setGoal(smartGoal)}
                                                    >
                                                        Swap Goal
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn fw-bold text-white bg-secondary rounded-pill px-4" onClick={onClose}>
                    Close
                </button>
                <button type="button" className="btn btn-primary-custom" onClick={handleSaveAndClose}>
                    Save Goal and Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default GoalModal;
