import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import Navbar from "../components/page/Navbar";
import Footer from "../components/page/Footer";
import SideBar from "../components/page/SideBar";
import { AuthContext } from "../context/AuthContext";
import { api } from "../api/api.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faCheck, faCheckCircle, faBullseye, faSpinner, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Card, Button, Collapse } from 'react-bootstrap';
import { toast } from 'react-toastify';

const Home = () => {
    const { user } = useContext(AuthContext);

    const focusAreas = user?.focusAreas || [];
    const challenges = user?.challenges || [];

    const [focusRecommendations, setFocusRecommendations] = useState([]);
    const [recommendationsLoading, setRecommendationsLoading] = useState(false);

    const [motivationalMessage, setMotivationalMessage] = useState("");
    const [lastMessageDate, setLastMessageDate] = useState(localStorage.getItem('lastMessageDate') || '');
    const [motivationLoading, setMotivationLoading] = useState(false);

    const [visibleCount, setVisibleCount] = useState(5);
    const [visibleGoalsCount, setVisibleGoalsCount] = useState({});
    const [visibleHabitsCount, setVisibleHabitsCount] = useState({});

    // Section collapse states
    const [openSections, setOpenSections] = useState({
        motivation: true,
        focusAreas: true,
        challenges: true
    });

    const toggleSection = (section) => {
        setOpenSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const handleShowMore = () => {
        setVisibleCount(prevCount => prevCount + 5);
    };

    const handleShowMoreGoals = (focusArea) => {
        setVisibleGoalsCount(prev => ({
            ...prev,
            [focusArea]: (prev[focusArea] || 5) + 5
        }));
    };

    const handleShowMoreHabits = (focusArea) => {
        setVisibleHabitsCount(prev => ({
            ...prev,
            [focusArea]: (prev[focusArea] || 5) + 5
        }));
    };

    const showToast = (message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT, // Change this if needed
            autoClose: 3000, // Duration before the toast closes
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progressClassName: 'custom-toast-progress',
            className: 'custom-toast', // Add custom class for styling
        });
    };

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );

    const getFocusAreasInfo = async (focusAreas) => {
        console.log("AIPlan: getFocusAreasInfo");

        // Set loading state to true for each focus area
        setRecommendationsLoading(true);

        console.log("AIPlan: passed focusAreas", focusAreas);

        try {
            // Fetch related goals and habits for each focus area
            const res = await api.post(`/api/ai/runfocusareasprompt`, { focusAreas: focusAreas });
            const data = res.data.data;
            console.log(data);
            console.log("Home: getFocusAreasInfo - updating focus area states", data);
            console.log("Data from API:", data);
            setFocusRecommendations(data);
            setRecommendationsLoading(false);

        } catch (error) {
            console.error("Error fetching related goals and habits", error);
        }
    };

    const getMotivationalMessage = async () => {
        setMotivationLoading(true);
        try {
            const res = await api.post(`/api/ai/runmotivationalprompt`, {
                focusAreas: focusAreas,
                challenges: challenges
            });
            const data = res.data.data;
            setMotivationalMessage(data.motivation);
            const today = new Date().toDateString();
            localStorage.setItem('lastMessageDate', today);
            setLastMessageDate(today);
        } catch (error) {
            console.error("Error fetching motivational message:", error);
        } finally {
            setMotivationLoading(false);
        }
    };

    const features = [
        {
            icon: faBullseye,
            title: "Goal Setting and Tracking",
            description: "Define your goals, track progress, and focus on priorities to achieve personal and professional milestones."
        },
        {
            icon: faLightbulb,
            title: "Habit Building and Analysis",
            description: "Cultivate habits that align with your goals, analyze trends, and track daily habits for sustained success."
        },
        {
            icon: faCheckCircle,
            title: "Productivity and Organization",
            description: "Enhance productivity, stay organized, and tackle challenges with confidence and determination."
        }
    ];

    useEffect(() => {
        if (user && user.focusAreas) {
            getFocusAreasInfo(user.focusAreas);
        }
    }, [user]);

    useEffect(() => {
        if (user && Array.isArray(focusAreas) && focusAreas.length > 0 && Array.isArray(challenges) && challenges.length > 0) {
            const today = new Date().toDateString();
            // if (lastMessageDate !== today) {
            //     getMotivationalMessage();
            // }

            getMotivationalMessage();

        }
    }, [user, focusAreas, challenges, lastMessageDate]);



    const handleAddGoal = async (goal, category) => {
        console.log("in handleAddGoal");
        console.log("goal", goal);
        console.log("category", category);

        try {
            const res = await api.post(`/api/goal/createuser`, {
                text: goal,
                description: goal,
                headline: goal,
                category: category,
                user: user._id,
            });

            // Show success toast
            showToast("Goal added successfully!");
        } catch (err) {
            console.error(err);
        }
    };

    const handleAddHabit = async (habit, category) => {
        console.log("in handleAddHabit");
        console.log("habit", habit);
        console.log("category", category);

        try {
            const res = await api.post(`/api/habit/createuser`, {
                text: habit,
                description: habit,
                headline: habit,
                category: category,
                user: user._id,
            });

            // Show success toast
            showToast("Habit added successfully!");
        } catch (err) {
            console.error(err);
        }
    };




    return (
        <div className="container-fluid">
            <Helmet>
                <title>Welcome to AImbition</title>
                <meta
                    name="description"
                    content="Achieve your goals with AImbition. Explore personalized insights and actionable tips tailored to your focus areas and challenges."
                />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>

                    </div>

                    {user ? (
                        <>
                            {Array.isArray(focusAreas) && focusAreas.length === 0 && (
                                <div className="alert alert-warning mt-3">
                                    You have not set any focus areas. Please <Link to="/profile">update your profile</Link> to set them.
                                </div>
                            )}
                            {Array.isArray(challenges) && challenges.length === 0 && (
                                <div className="alert alert-warning mt-3">
                                    You have not set any challenges. Please <Link to="/profile">update your profile</Link> to set them.
                                </div>
                            )}
                            {/* Section: Daily Motivation */}
                            <section className="mb-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="mb-0">Daily Motivation</h4>
                                    <Button
                                        onClick={() => toggleSection('motivation')}
                                        className="collapse-btn"
                                    >
                                        {openSections.motivation ? 'Hide' : 'Show'}
                                        <FontAwesomeIcon icon={openSections.motivation ? faChevronUp : faChevronDown} />
                                    </Button>
                                </div>
                                <Collapse in={openSections.motivation}>
                                    <div>
                                        <div className="col-md-8 col-sm-10 mb-3">
                                            <div className="card shadow-sm">
                                                <div className="card-body p-4">
                                                    {motivationLoading ? (
                                                        <div className="text-center py-4">
                                                            <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-muted" />
                                                        </div>
                                                    ) : (
                                                        <blockquote className="blockquote mb-0">
                                                            <p className="lead font-italic text-muted">
                                                                {motivationalMessage}
                                                            </p>
                                                        </blockquote>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </section>

                            {/* Section: Your Focus Areas */}
                            <section className="mb-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="mb-0">Your Focus Areas</h4>
                                    <Button
                                        onClick={() => toggleSection('focusAreas')}
                                        className="collapse-btn"
                                    >
                                        {openSections.focusAreas ? 'Hide' : 'Show'}
                                        <FontAwesomeIcon icon={openSections.focusAreas ? faChevronUp : faChevronDown} />
                                    </Button>
                                </div>
                                <Collapse in={openSections.focusAreas}>
                                    <div>
                                        <div className="row g-4">
                                            {recommendationsLoading ? (
                                                Array.from({ length: user?.focusAreas?.length || 0 }).map((_, index) => (
                                                    <div key={index} className="col-md-4 col-sm-6 mb-3">
                                                        <div className="card h-100 shadow-sm">
                                                            <div className="card-body d-flex flex-column gap-2 p-2">
                                                                <div className="d-flex align-items-center justify-content-start mx-auto py-1">
                                                                    <div className="me-2 align-self-center d-flex align-items-center">
                                                                        <FontAwesomeIcon icon={faBullseye} size="2x" className="text-secondary" />
                                                                    </div>
                                                                    <h4 className="card-title align-middle mb-0 d-flex align-items-center">
                                                                        <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                                                                    </h4>
                                                                </div>
                                                                <div className="card bg-white">
                                                                    <div className="card-body p-2">
                                                                        <h5 className="card-title mb-3">Relevant Goals:</h5>
                                                                        <div className="d-flex flex-wrap justify-content-center">
                                                                            <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card bg-white">
                                                                    <div className="card-body p-2">
                                                                        <h5 className="py-1 mb-3">Relevant Habits:</h5>
                                                                        <div className="d-flex flex-wrap justify-content-center">
                                                                            <FontAwesomeIcon icon={faSpinner} spin size="lg" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                focusRecommendations.slice(0, visibleCount).map(({ focusArea, relatedGoals, relatedHabits }, index) => (
                                                    <div key={index} className="col-md-4 col-sm-6 mb-3">
                                                        <div className="card h-100 shadow-sm">
                                                            <div className="card-body d-flex flex-column gap-2 p-2">
                                                                <div className="d-flex align-items-center justify-content-start mx-auto py-1">
                                                                    <div className="me-2 align-self-center d-flex align-items-center">
                                                                        <FontAwesomeIcon icon={faBullseye} size="2x" className="text-secondary" />
                                                                    </div>
                                                                    <h4 className="card-title align-middle mb-0 d-flex align-items-center">{focusArea}</h4>
                                                                </div>
                                                                <div className="card bg-white">
                                                                    <div className="card-body p-2">
                                                                        <h5 className="card-title mb-3">Popular Goals:</h5>
                                                                        <div className="d-flex flex-wrap gap-2">
                                                                            {(relatedGoals || []).slice(0, visibleGoalsCount[focusArea] || 5).map((goal, index) => (
                                                                                <Button key={index} type="button" className="btn btn-primary-custom" onClick={() => handleAddGoal(goal, focusArea)}>
                                                                                    {goal}
                                                                                </Button>
                                                                            ))}
                                                                        </div>
                                                                        {(relatedGoals || []).length > (visibleGoalsCount[focusArea] || 5) && (
                                                                            <div className="text-end mt-3">
                                                                                <Button type="button" className="btn bg-secondary btn-sm" onClick={() => handleShowMoreGoals(focusArea)}>
                                                                                    Show More..
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="card bg-white">
                                                                    <div className="card-body p-2">
                                                                        <h5 className="py-1 mb-3">Popular Habits:</h5>
                                                                        <div className="d-flex flex-wrap gap-2">
                                                                            {(relatedHabits || []).slice(0, visibleHabitsCount[focusArea] || 5).map((habit, index) => (
                                                                                <Button key={index} type="button" className="btn btn-primary-custom" onClick={() => handleAddHabit(habit, focusArea)}>
                                                                                    {habit}
                                                                                </Button>
                                                                            ))}
                                                                        </div>
                                                                        {(relatedHabits || []).length > (visibleHabitsCount[focusArea] || 5) && (
                                                                            <div className="text-end mt-3">
                                                                                <Button type="button" className="btn bg-secondary btn-sm" onClick={() => handleShowMoreHabits(focusArea)}>
                                                                                    Show More..
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                        {visibleCount < focusRecommendations.length && (
                                            <div className="d-flex justify-content-center mt-3">
                                                <Button type="button" className="btn btn-secondary" onClick={handleShowMore}>
                                                    Show More
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </Collapse>
                            </section>

                            {/* Section: Your Challenges */}
                            <section className="mb-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="mb-0">Your Challenges</h4>
                                    <Button
                                        onClick={() => toggleSection('challenges')}
                                        className="collapse-btn"
                                    >
                                        {openSections.challenges ? 'Hide' : 'Show'}
                                        <FontAwesomeIcon icon={openSections.challenges ? faChevronUp : faChevronDown} />
                                    </Button>
                                </div>
                                <Collapse in={openSections.challenges}>
                                    <div>
                                        <div className="row g-4">
                                            {challenges.map((challenge, index) => (
                                                <div key={index} className="col-md-4 col-sm-6 mb-3">
                                                    <div className="card h-100">
                                                        <div className="card-body d-flex flex-column gap-3">
                                                            <div className="d-flex align-items-center mb-3 mx-auto">
                                                                <div className="me-2 align-self-center d-flex align-items-center">
                                                                    <FontAwesomeIcon icon={faLightbulb} size="2x" className="text-secondary" />
                                                                </div>
                                                                <h4 className="card-title align-middle mb-0 d-flex align-items-center">{challenge}</h4>
                                                            </div>
                                                            <p className="card-text">
                                                                Here's a tip to overcome this challenge: Break it into smaller, manageable tasks and stay consistent.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Collapse>
                            </section>
                        </>
                    ) : (
                        <div className="row d-flex justify-content-between">
                            <div className="col-md-1 col-sm-0 py-2"></div>
                            {features.map((feature, index) => (
                                <div key={index} className="col-md-3 col-sm-12 py-2">
                                    <div className="card text-center border-0 h-100">
                                        <div className="card-header bg-pr-clr fw-bold">
                                            {feature.title}
                                        </div>
                                        <div className="card-body">
                                            <FontAwesomeIcon icon={feature.icon} size="2x" className="mb-3 bg-sec-clr" />
                                            <div className="d-flex justify-content-center">
                                                <p className="card-text">{feature.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="col-md-1 col-sm-0 py-2"></div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
        </div >
    );
};

export default Home;
