import { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import Footer from "../components/page/Footer";
import { api } from "../api/api.js";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe('pk_test_51NfMm2Cwvcp7G6axIcvTtQlKUVL7yw8djLMSu5S9pHCLHS9V3I3vJvYztmXKrLgckrWGcY43yBLB6f7cyX8Jp0vZ00uOAfx5KV');

const RegisterMultiStep = () => {
    const [step, setStep] = useState(1);
    const [info, setInfo] = useState({
        focusAreas: [],
        challenges: [],
        username: "",
        email: "",
        password: "",
        goalsLevel: "free"
    });
    const [challenges, setChallenges] = useState([]);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const focusAreas = [
        "Career", "Self-Development", "Family", "Finance", "Fun", "Health", "Relationship", "Spiritual"
    ];

    const challengeAreas = [
        'No Clarity',      // Understanding what you want
        'No Strategy',     // How to achieve it
        'Procrastinate',   // Taking action
        'Inconsistent',    // Maintaining momentum
        'Fear of Failure',    // Mental block
        'Overwhelmed',     // Too much at once
        'No Resources',    // Missing tools/support
        'Distracted',      // Environment issues
        'Negative',        // Self-doubt
        'No Time',         // Balancing priorities
        'No Support',      // Staying on track
        'Unrealistic'      // Setting achievable targets
    ];

    const handleFocusAreaChange = (area) => {
        console.log('Current focusAreas:', info.focusAreas);
        console.log('Toggling area:', area);

        setInfo(prev => {
            const isSelected = prev.focusAreas.includes(area);
            console.log('Is currently selected:', isSelected);

            const newFocusAreas = isSelected
                ? prev.focusAreas.filter(a => a !== area)
                : [...prev.focusAreas, area];

            console.log('New focusAreas:', newFocusAreas);
            return { ...prev, focusAreas: newFocusAreas };
        });
    };

    const handleChallengeChange = (challenge) => {
        console.log('Current challenges:', info.challenges);
        console.log('Toggling challenge:', challenge);

        setInfo(prev => {
            const isSelected = prev.challenges.includes(challenge);
            console.log('Is currently selected:', isSelected);

            const newChallenges = isSelected
                ? prev.challenges.filter(c => c !== challenge)
                : [...prev.challenges, challenge];

            console.log('New challenges:', newChallenges);
            return { ...prev, challenges: newChallenges };
        });
    };

    const handleChange = (e) => {
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await api.post("/api/auth/register", { ...info });
            if (res.status === 200) {
                setMessage("Registration success - Check your email for activation link.");
                setErrorMessage(undefined);
            } else {
                setErrorMessage(res.data.message || "An unknown error occurred");
                setMessage(undefined);
            }
        } catch (err) {
            setErrorMessage(err.response?.data?.message || "Could not register");
            setMessage(undefined);
        }
    };

    const nextStep = () => {
        if (step === 1 && info.focusAreas.length === 0) {
            setErrorMessage("Please select at least one focus area");
            return;
        }
        if (step === 2 && info.challenges.length === 0) {
            setErrorMessage("Please select at least one challenge");
            return;
        }
        setStep(prev => prev + 1);
        setErrorMessage(undefined);
    };

    const prevStep = () => {
        setStep(prev => prev - 1);
        setErrorMessage(undefined);
    };

    const renderStep1 = () => (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Select Your Goal Focus Areas</h5>
            <div className="row g-3">
                {focusAreas.map((area) => {
                    const isSelected = info.focusAreas.includes(area);
                    console.log(`Rendering ${area}, selected:`, isSelected);

                    return (
                        <div key={area} className="col-6 col-md-4">
                            <button
                                type="button"
                                style={{ fontSize: '.8rem' }}
                                className={`btn w-100 ${isSelected ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                onClick={() => handleFocusAreaChange(area)}
                            >
                                {area}
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex justify-content-end mt-4">
                <button
                    type="button"
                    className="btn btn-primary-custom"
                    onClick={nextStep}
                >
                    Next
                </button>
            </div>
        </div>
    );

    const renderStep2 = () => (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Select Your Common Challenges</h5>
            <div className="row g-3">
                {challengeAreas.map((challenge) => {
                    const isSelected = info.challenges.includes(challenge);
                    return (
                        <div key={challenge} className="col-6 col-md-4">
                            <button
                                type="button"
                                style={{ fontSize: '.9rem' }}
                                className={`btn w-100 h-100 ${isSelected ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                onClick={() => handleChallengeChange(challenge)}
                            >
                                {challenge}
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button className="btn btn-primary-custom" onClick={nextStep}>
                    Next
                </button>
            </div>
        </div>
    );

    const renderStep3 = () => (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Choose Your Plan</h5>
            <div className="row g-4">
                <div className="col-md-6">
                    <div className={`card h-100 ${info.goalsLevel === 'free' ? 'border-secondary' : ''}`}>
                        <div className="card-body d-flex flex-column justify-content-between">
                            <div>
                                <h5 className="card-title">Free Plan</h5>
                                <ul className="list-unstyled">
                                    <li>✓ Basic goal tracking</li>
                                    <li>✓ Limited AI assistance</li>
                                    <li>✓ Basic analytics</li>
                                </ul>
                            </div>
                            <button
                                className={`btn ${info.goalsLevel === 'free' ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                onClick={() => setInfo(prev => ({ ...prev, goalsLevel: 'free' }))}
                            >
                                Select Free Plan
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={`card h-100 ${info.goalsLevel === 'plus' ? 'border-secondary' : ''}`}>
                        <div className="card-body d-flex flex-column justify-content-between">
                            <div>
                                <h5 className="card-title">Plus Plan</h5>
                                <ul className="list-unstyled">
                                    <li>✓ Unlimited goal tracking</li>
                                    <li>✓ Advanced AI assistance</li>
                                    <li>✓ Detailed analytics</li>
                                    <li>✓ Priority support</li>
                                </ul>
                            </div>
                            <button
                                className={`btn ${info.goalsLevel === 'plus' ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                onClick={() => setInfo(prev => ({ ...prev, goalsLevel: 'plus' }))}
                            >
                                Select Plus Plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button className="btn btn-primary-custom" onClick={nextStep}>
                    Next
                </button>
            </div>
        </div>
    );
    const renderStep4 = () => (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Complete Your Registration</h5>
            {info.email ? (
                <div>
                    <p>Welcome {info.username || info.email}! 🎉</p>
                    <p>Click below to complete your registration.</p>
                    <div className="d-flex justify-content-between">
                        <button className="btn btn-secondary" onClick={prevStep}>
                            Back
                        </button>
                        <button
                            className="btn btn-primary-custom"
                            onClick={handleSubmit}
                        >
                            Finish Registration
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            value={info.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={info.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            value={info.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                        <button className="btn btn-secondary" onClick={prevStep}>
                            Back
                        </button>
                        <button className="btn btn-primary-custom" onClick={handleSubmit}>
                            Register
                        </button>
                    </div>
                </>
            )}
            <div className="text-center my-4">
                <hr className="my-3" />

                <h6 className="card-title mb-4">or</h6>
                <h5 className="card-title mb-4">or Register with Google</h5>

                {!info.email && (
                    <div className="d-flex justify-content-center">
                        <GoogleLogin
                            className="mx-auto"
                            buttonText="Register with Google"
                            onSuccess={handleGoogleAuthResponse}
                            onFailure={handleGoogleFailure}
                        />
                    </div>
                )}
            </div>
        </div>
    );


    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleGoogleAuthResponse = async (response) => {
        console.log("in handleGoogleAuthResponse");
        const tokenId = response.credential;

        try {
            // Verify Google token with backend
            const res = await api.post('/api/auth/validate-google', { token: tokenId });
            if (res.status === 200) {
                console.log("Google verification successful. User details:", res.data);

                // Populate info state with Google details
                setInfo(prev => ({
                    ...prev,
                    email: res.data.email || "", // Ensure email is a string
                    username: res.data.name || "", // Check if email is defined
                    password: "", // Password not required for Google users
                }));

                // Move to the next step if applicable
                setStep(4); // Ensure they're on the final registration step
            }
        } catch (err) {
            console.error("Error during Google registration:", err);
            setErrorMessage(err.response?.data?.message || "Google registration failed");
        }
    };


    const handleGoogleFailure = (error) => {
        console.log("in handleGoogleFailure");
        console.error("Google Sign In was unsuccessful. Try Again Later.", error);
    };

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Register - Goals & Habits Management</title>
                <meta name="description" content="Join Goals & Habits to start your journey towards a more fulfilling life. Set empowering goals and cultivate productive habits." />
            </Helmet>
            <Navbar />
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <div className="card shadow">
                            <div className="card-header">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0">Register</h4>
                                    <div className="text-muted">Step {step} of 4</div>
                                </div>
                                <div className="progress mt-3" style={{ height: "2px" }}>
                                    <div
                                        className="progress-bar bg-secondary"
                                        role="progressbar"
                                        style={{ width: `${(step / 4) * 100}%` }}
                                    />
                                </div>
                            </div>
                            {errorMessage && (
                                <div className="alert alert-danger m-3">
                                    {errorMessage}
                                </div>
                            )}
                            {message && (
                                <div className="alert alert-success m-3">
                                    {message}
                                </div>
                            )}
                            {step === 1 && renderStep1()}
                            {step === 2 && renderStep2()}
                            {step === 3 && renderStep3()}
                            {step === 4 && renderStep4()}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RegisterMultiStep;
