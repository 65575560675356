import React, { useState, useContext, useEffect, useRef } from "react";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import SideBar from "../components/page/SideBar";
import Footer from "../components/page/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faClipboardList, faExclamationTriangle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';

import { useLocation } from 'react-router-dom';



import EditModal from "../components/page/EditModal";
import EntityComponent from '../components/page/EntityComponent';
import { todayStart } from "../utils/theDateHelper.js";

import GoalModal from "../components/modal/GoalModal";
import ChallengesModal from "../components/modal/ChallengesModal";
import PlanModal from "../components/modal/PlanModal";

const AIPlan = () => {

    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState({
        milestones: false,
        habits: false,
        tasks: false,
        assessment: false,
        challenges: false
    });
    const location = useLocation();


    const [goal, setGoal] = useState('');
    const [goalStartDate, setGoalStartDate] = useState(() => {
        return todayStart().toISOString().split('T')[0];
    });
    const [goalDeadline, setGoalDeadline] = useState('');

    const [originalGoal, setOriginalGoal] = useState('');
    const [goalEntity, setGoalEntity] = useState([]);
    const [goalAssessment, setGoalAssessment] = useState([]);
    const [goalChallenges, setGoalChallenges] = useState([]);

    const [goalSaved, setGoalSaved] = useState(false);

    const [milestones, setMilestones] = useState([]);
    const [habits, setHabits] = useState([]);
    const [tasks, setTasks] = useState([]);

    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentEntity, setCurrentEntity] = useState({ type: "", index: -1, value: "" });
    const goalIDRef = useRef("");

    // Modal visibility states
    const [showGoalModal, setShowGoalModal] = useState(false);
    const [showChallengesModal, setShowChallengesModal] = useState(false);
    const [showPlanModal, setShowPlanModal] = useState(false);



    // useEffect(() => {
    //     console.log("AIPlan: in useEffect [Basic]");

    //     console.log("UseEffect: Goal Entity Updated:", goalEntity);
    //     console.log("UseEffect: Milestones Updated:", milestones);
    //     console.log("UseEffect: Habits Updated:", habits);
    //     console.log("UseEffect: Tasks Updated:", tasks);
    //     console.log("UseEffect: GoalSaved successfully saved - goal, goalID goalSaved:", goal, goalIDRef.current, goalSaved);
    // }, [goalEntity, milestones, habits, tasks, goalIDRef.current, goalSaved]);

    useEffect(() => {
        console.log("AIPlan: in useEffect", location.search);

        const queryString = location.search;
        const goalParam = queryString.match(/goal=([^&]*)/);

        if (goalParam) {
            const goalValue = decodeURIComponent(goalParam[1]);
            console.log('Goal value:', goalValue);

            setGoal(goalValue);
            setOriginalGoal(goalValue);
            setGoalSaved(false);
            goalIDRef.current = "";
            setGoalEntity([]);
            setMilestones([]);
            setHabits([]);
            setTasks([]);
        }
    }, [location.search]);

    const handleGoalChange = (e) => {
        setGoal(e.target.value);
        setOriginalGoal(e.target.value);
        setGoalSaved(false);
    };


    const createEntityComponent = (index, type, text, saved, saveEntity, editEntity, removeEntity) => (
        <EntityComponent
            key={index}
            type={type}
            text={text}
            saved={saved}
            saveEntity={() => saveEntity(type.toLowerCase(), text, index)}
            editEntity={() => editEntity(type.toLowerCase(), text, index)}
            removeEntity={() => removeEntity(type.toLowerCase(), index)}
        />
    );

    const handleAdd = async (entityType, entityText) => {
        console.log("AIPlan: handleAdd entityType, entityText, goalID, goalSaved:", entityType, entityText, goalIDRef.current, goalSaved);

        try {
            let url = `/api/${entityType}/createuser`;
            let body = {
                text: entityText,
                description: entityText,
                headline: entityText,
                category: "AIPlan",
                user: user._id,
            };

            if (entityType !== 'goal') {
                console.log("AIPlan: entityType !== goal and goalID", entityType, goalIDRef.current);
                body.goal = goalIDRef.current;
            }
            const response = await api.post(url, body);
            console.log("AIPlan: response.data._id", response.data._id);

            return response.data;
        } catch (err) {
            console.error(`Error adding ${entityType}:`, err);
        }
    };

    const assessGoal = async (entityType) => {
        console.log("AIPlan: assessGoal");

        setLoading(prevLoading => ({
            ...prevLoading,
            assessment: loading,
        }));

        try {
            const res = await api.post(`/api/ai/runassessmentprompt`, { goal, goalStartDate, goalDeadline });
            const data = res.data.data;
            console.log("AIPlan: data", data);

            // Extract relevant data from the response
            const { feedback, ratings, strategies, smartGoals } = data;

            // Format the ratings for display
            const formattedRatings = Object.keys(ratings).map(key => (
                <div key={key}>
                    <strong>{key}:</strong> {ratings[key]}
                </div>
            ));

            // // Format the smart goals for display
            // const formattedSmartGoals = smartGoals.map((goal, index) => (
            //     <div key={index}>
            //         {goal}
            //     </div>
            // ));

            // Update the state with the formatted data
            setGoalAssessment({
                feedback,
                ratings: formattedRatings,
                strategies: strategies,
                smartGoals: smartGoals,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(prevLoading => ({
                ...prevLoading,
                assessment: false,
            }));
        }
    };

    const executePlanAI = async (entityType) => {
        console.log("AIPlan: executePlanAI");
        console.log("AIPlan: entityType:", entityType);
        console.log("AIPlan: goal:", goal);
        console.log("AIPlan: goalStartDate:", goalStartDate);
        console.log("AIPlan: goalDeadline:", goalDeadline);

        const setLoadingState = (loading) => {
            if (entityType === 'plan') {
                setLoading(prevLoading => ({
                    ...prevLoading,
                    milestones: loading,
                    habits: loading,
                    tasks: loading
                }));
            } else {
                setLoading(prevLoading => ({ ...prevLoading, [entityType]: loading }));
            }
        };

        setLoadingState(true);

        try {
            console.log("AIPlan: goal, goalStartDate, goalDeadline:", goal, goalStartDate, goalDeadline);
            const res = await api.post(`/api/ai/run${entityType}prompt`, { goal, goalStartDate, goalDeadline });
            const data = res.data.data;

            console.log("AIPlan: data:", data);

            if (entityType === 'plan') {
                setGoalEntity([createEntityComponent(0, "goal", data.goal, false, saveEntity, handleEditClick, removeEntity)]);
                setMilestones(data.milestones.map((text, index) =>
                    createEntityComponent(index, "milestone", text, false, saveEntity, handleEditClick, removeEntity)
                ));

                console.log("AIPlan: milestones:", milestones);

                const tempArray = data.habits.map((text, index) =>
                    createEntityComponent(index, "habit", text, false, saveEntity, handleEditClick, removeEntity));

                console.log("AIPlan: tempArray:", tempArray);

                setHabits(tempArray);
                setTasks(data.tasks.map((text, index) =>
                    createEntityComponent(index, "task", text, false, saveEntity, handleEditClick, removeEntity)
                ));
            } else {
                const entityMap = {
                    milestones: setMilestones,
                    habits: setHabits,
                    tasks: setTasks,
                };
                console.log("AIPlan: entityMap[entityType]:", entityMap[entityType]);
                entityMap[entityType](data[entityType].map((text, index) =>
                    createEntityComponent(index, entityType.slice(0, -1), text, false, saveEntity, handleEditClick, removeEntity)
                ));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingState(false);
        }
    };

    const editEntity = (entityType, entity, index) => {
        console.log("AIPlan: editEntity");

        switch (entityType) {
            case 'goal':
                setGoalEntity([createEntityComponent(0, "goal", entity, false, saveEntity, handleEditClick, removeEntity)]);
                break;
            case 'milestone':
                setMilestones(
                    milestones.map((milestone, i) => {
                        if (i === index) {
                            return createEntityComponent(i, "milestone", entity, false, saveEntity, handleEditClick, removeEntity);
                        }
                        return milestone;
                    })
                );
                break;
            case 'habit':
                setHabits(
                    habits.map((habit, i) => {
                        if (i === index) {
                            return createEntityComponent(i, "habit", entity, false, saveEntity, handleEditClick, removeEntity);
                        }
                        return habit;
                    })
                );
                break;
            case 'task':
                setTasks(
                    tasks.map((task, i) => {
                        if (i === index) {
                            return createEntityComponent(i, "task", entity, false, saveEntity, handleEditClick, removeEntity);
                        }
                        return task;
                    })
                );
                break;
            default:
                console.error(`Unknown entity type: ${entityType}`);
        }
    };

    const removeEntity = (entityType, index) => {
        console.log("AIPlan: removeEntity");

        switch (entityType.toLowerCase()) {
            case 'goal':
                setGoalEntity([]);
                break;
            case 'milestone':
                updateEntities(milestones, setMilestones);
                break;
            case 'habit':
                updateEntities(habits, setHabits);
                break;
            case 'task':
                updateEntities(tasks, setTasks);
                break;
            default:
                console.error(`Unknown entity type: ${entityType}`);
        }
    };

    const updateEntities = (entities, setEntities, type, index, text) => {
        console.log("AIPlan: updateEntities")

        const updatedEntities = [...entities];
        const tempEntity = createEntityComponent(index, type, text, true, saveEntity, handleEditClick, removeEntity);

        updatedEntities[index] = tempEntity;

        setEntities(updatedEntities);
    };

    const saveEntity = async (entityType, text, index) => {
        console.log("AIPlan: saveEntity - entityType, GoalSaved, goalID, goalEntity:", entityType, goalSaved, goalIDRef.current, goalEntity);

        const tempData = await handleAdd(entityType, text);
        console.log("AIPlan: saveEntity: tempData", tempData);

        if (entityType === 'goal') {
            console.log("AIPlan: saveEntity: type is goal");

            setGoalSaved(true);
            goalIDRef.current = tempData._id;

            setGoalEntity((prevGoalEntity) => {
                const updatedEntity = createEntityComponent(index, "goal", text, true, saveEntity, handleEditClick, removeEntity);
                return [...prevGoalEntity.slice(0, index), updatedEntity, ...prevGoalEntity.slice(index + 1)];
            });
        }
        else {
            console.log("AIPlan: saveEntity: type is", entityType);
            if (entityType === 'milestone') {
                setMilestones((prevMilestones) => {
                    const updatedEntity = createEntityComponent(index, "milestone", text, true, saveEntity, handleEditClick, removeEntity);
                    return [...prevMilestones.slice(0, index), updatedEntity, ...prevMilestones.slice(index + 1)];
                });
            } else if (entityType === 'habit') {
                setHabits((prevHabits) => {
                    const updatedEntity = createEntityComponent(index, "habit", text, true, saveEntity, handleEditClick, removeEntity);
                    return [...prevHabits.slice(0, index), updatedEntity, ...prevHabits.slice(index + 1)];
                });
            } else if (entityType === 'task') {
                setTasks((prevTasks) => {
                    const updatedEntity = createEntityComponent(index, "task", text, true, saveEntity, handleEditClick, removeEntity);
                    return [...prevTasks.slice(0, index), updatedEntity, ...prevTasks.slice(index + 1)];
                });
            }
        }
    };

    const handleEditClick = (entityType, entity, index) => {
        console.log("AIPlan: handleEditClick");
        console.log("AIPlan: entityType, entity, index:", entityType, entity, index);

        setCurrentEntity({ type: entityType, index, value: entity });
        setEditModalOpen(true);
    };

    const handleEditChange = (e) => {
        console.log("AIPlan: handleEditChange");
        setCurrentEntity({ ...currentEntity, value: e.target.value });
    };

    const handleEditSubmit = () => {
        console.log("AIPlan: handleEditSubmit");
        const { type, index, value } = currentEntity;
        console.log("AIPlan: handleEditSubmit: type, index, value:", type, index, value);
        editEntity(type, value, index);
        setEditModalOpen(false);
    };

    const generatePlan = async (e) => {
        console.log("AIPlan: generatePlan");
        e.preventDefault();

        setGoalSaved(false);
        goalIDRef.current = "";  // Clear the goal ID reference
        setGoalEntity([]);       // Clear previous goal entities
        setMilestones([]);       // Clear milestones
        setHabits([]);           // Clear habits
        setTasks([]);            // Clear tasks

        // Call executePlanAI with 'plan' as the entity type
        await executePlanAI('plan');
    };


    const identifyChallenges = async (e) => {
        console.log("AIPlan: identifyChallenges");

        setLoading(prevLoading => ({
            ...prevLoading,
            challenges: loading,
        }));

        try {
            const res = await api.post(`/api/ai/runchallengesprompt`, { goal, goalStartDate, goalDeadline });
            const data = res.data.data;
            console.log("AIPlan: data", data);

            // Extract relevant data from the response
            const { feedback, challenges } = data;

            // Format the challenges for display
            // const formattedChallenges = challenges.map((challenge, index) => (
            //     <div key={index}>
            //         <strong>Challenge {index + 1}:</strong> {challenge}
            //     </div>
            // ));

            // Update the state with the formatted data
            setGoalChallenges({
                feedback,
                challenges: challenges
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(prevLoading => ({
                ...prevLoading,
                challenges: false,
            }));
        }
    };

    // console.log('AIPlan: About to Render', goal, milestones, habits, tasks, goalSaved, goalIDRef.current);
    return (
        <div className="container-fluid">
            <Helmet>
                <title>Empower Your Life with Goals & Habits Management</title>
                <meta name="description" content="A suite of tools for goal setting and management." />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row">
                        <div className="col">
                            <Header />
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                        <div className="col d-flex align-items-center">
                            <h3 className="mb-0">Goal Plan AI</h3>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                        <div className="col-md-1 col-sm-0 py-2"></div>
                        <div className="col-md-3 col-sm-12 py-2">
                            <div className="card text-center shadow-lg border-0 h-100">
                                <div className="card-header bg-pr-clr fw-bold">
                                    Step 1
                                </div>
                                <div className="card-body">
                                    <div>
                                        <FontAwesomeIcon icon={faRedo} size="2x" className="mb-3" />
                                        <h5 className="card-title fw-bold">Refine Your Goal</h5>
                                        <div className="d-flex justify-content-center">
                                            <ul className="list-unstyled text-muted text-start">
                                                <li><span className="fw-semibold">✓</span> Assess your goal</li>
                                                <li><span className="fw-semibold">✓</span> Refine as needed</li>
                                                <li><span className="fw-semibold">✓</span> Save goal</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <button
                                        className="btn btn-primary-custom w-50"
                                        onClick={() => setShowGoalModal(true)}
                                    >
                                        Start Refining
                                    </button>
                                    {goalSaved ? (
                                        <div className="rounded py-2 border mt-3">
                                            <FontAwesomeIcon icon={faCheckCircle} className="me-1 text-muted" />
                                            <span className="fw-semibold">Goal Saved:</span> <br />
                                            <div className="form-control bg-transparent border-0 text-start text-muted">
                                                {goal}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="rounded py-2 border mt-3">
                                            <span className="fw-semibold">Goal:</span>
                                            <div className="form-control bg-transparent border-0 text-start text-muted">
                                                {goal || "Start refining your goal..."}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className="col-md-3 col-sm-12 py-2">
                            <div className="card text-center shadow-lg border-0 h-100">
                                <div className="card-header bg-pr-clr fw-bold">
                                    Step 2
                                </div>
                                <div className="card-body">
                                    <FontAwesomeIcon icon={faClipboardList} size="2x" className="mb-3" />
                                    <h5 className="card-title fw-bold">Create a Plan</h5>
                                    <div className="d-flex justify-content-center">
                                        <ul className="list-unstyled mb-3 text-muted text-start">
                                            <li><span className="fw-semibold">✓</span> Establish milestones</li>
                                            <li><span className="fw-semibold">✓</span> Set habits</li>
                                            <li><span className="fw-semibold">✓</span> Assign tasks</li>
                                        </ul>
                                    </div>
                                    <button
                                        className="btn btn-primary-custom w-50"
                                        onClick={() => setShowPlanModal(true)}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-12 py-2">
                            <div className="card text-center shadow-lg border-0 h-100">
                                <div className="card-header bg-pr-clr fw-bold">
                                    Step 3
                                </div>
                                <div className="card-body">
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="mb-3" />
                                    <h5 className="card-title fw-bold">Understand Challenges</h5>
                                    <div className="d-flex justify-content-center">
                                        <ul className="list-unstyled text-muted text-start">
                                            <li><span className="fw-semibold">✓</span> Identify potential obstacles</li>
                                            <li><span className="fw-semibold">✓</span> Prepare for challenges</li>
                                            <li><span className="fw-semibold">✓</span> Develop strategies</li>
                                        </ul>
                                    </div>
                                    <button
                                        className="btn btn-primary-custom w-50"
                                        onClick={() => setShowChallengesModal(true)}
                                    >
                                        Understand
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-1 col-sm-0 py-2"></div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
            {/* Modals */}
            <GoalModal
                show={showGoalModal}
                onClose={() => setShowGoalModal(false)}
                goal={goal}
                goalEntity={goalEntity}
                goalStartDate={goalStartDate}
                goalDeadline={goalDeadline}
                goalAssessment={goalAssessment}
                setGoal={setGoal}
                setGoalStartDate={setGoalStartDate}
                setGoalDeadline={setGoalDeadline}
                assessGoal={assessGoal}
                setGoalEntity={setGoalEntity}
                createEntityComponent={createEntityComponent}
                saveEntity={saveEntity}
                loading={loading.assessment}
                setLoading={setLoading}
                handleGoalChange={handleGoalChange}
            />
            <PlanModal
                show={showPlanModal}
                onClose={() => setShowPlanModal(false)}
                goal={goal}
                milestones={milestones}
                habits={habits}
                tasks={tasks}
                loading={loading.plan}
                setLoading={setLoading}
                executePlanAI={executePlanAI}
            />
            <ChallengesModal
                show={showChallengesModal}
                onClose={() => setShowChallengesModal(false)}
                identifyChallenges={identifyChallenges}
                goalChallenges={goalChallenges}
                goal={goal}
                loading={loading.challenges}
                setLoading={setLoading}
            />

        </div >
    );
};

export default AIPlan;
