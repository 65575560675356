import React from "react";
import GoalSummaryChart from './GoalSummaryChart';

const GoalStatusWidget = ({ goalStats, totalGoals }) => {
    const content = totalGoals === 0 ? (
        <div className="text-center p-3">
            <p>No goals yet! Ready to set some?</p>
            <a href="/goals" className="btn btn-primary-custom">Go to Goals Page</a>
        </div>
    ) : (
        <GoalSummaryChart goalStats={goalStats} totalGoals={totalGoals} />
    );

    return (
        <div className="col-md-4 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold bg-pr-clr">Goal Status</div>
                <div className="flex-grow-1">{content}</div>
                <div className="p-2 footer-border bg-pr-clr">{totalGoals} Goals</div>
            </div>
        </div>
    );
};

export default GoalStatusWidget;
