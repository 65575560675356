import { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import SideBar from "../components/page/SideBar";
import Footer from "../components/page/Footer";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Profile = () => {
    const { user } = useContext(AuthContext);
    const [info, setInfo] = useState({
        username: user?.username || "",
        email: user?.email || "",
        password: "",
        confirmPassword: "",
        focusAreas: user?.focusAreas || [],
        challenges: user?.challenges || [],
        goalsLevel: user?.goalsLevel || "free"
    });
    const { loading, error, dispatch } = useContext(AuthContext);
    const [subscriptions, setSubscriptions] = useState([]);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [focusAreaError, setFocusAreaError] = useState("");
    const [challengeError, setChallengeError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const focusAreas = [
        "Career", "Self-Development", "Family", "Finance", "Fun", "Health", "Relationship", "Spiritual"
    ];

    const challengeAreas = [
        'No Clarity', 'No Strategy', 'Procrastinate', 'Inconsistent', 'Fear of Failure',
        'Overwhelmed', 'No Resources', 'Distracted', 'Negative', 'No Time',
        'No Support', 'Unrealistic'
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                const subscriptions = await api.get("/api/stripe/getsubscriptions");
                setSubscriptions(subscriptions.data);
            }
        }
        fetchData();
    }, [user]);

    const handleChange = (e) => {
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value || "" }));
    };

    const handleFocusAreaChange = (area) => {
        setInfo(prev => {
            const isSelected = prev.focusAreas.includes(area);
            const newFocusAreas = isSelected
                ? prev.focusAreas.filter(a => a !== area)
                : [...prev.focusAreas, area];
            return { ...prev, focusAreas: newFocusAreas };
        });
        setFocusAreaError("");
    };

    const handleChallengeChange = (challenge) => {
        setInfo(prev => {
            const isSelected = prev.challenges.includes(challenge);
            const newChallenges = isSelected
                ? prev.challenges.filter(c => c !== challenge)
                : [...prev.challenges, challenge];
            return { ...prev, challenges: newChallenges };
        });
        setChallengeError("");
    };

    const handleUpdate = async (e) => {
        console.log("in handleUpdate");
        console.log("user", user);

        e.preventDefault();
        if (info.password !== info.confirmPassword) {
            setPasswordError("Passwords do not match");
            return;
        }
        if (info.focusAreas.length === 0) {
            setFocusAreaError("Please select at least one focus area.");
            return;
        }
        if (info.challenges.length === 0) {
            setChallengeError("Please select at least one challenge.");
            return;
        }
        setPasswordError("");
        try {
            const res = await api.put(`/api/auth/${user._id}`,
                {
                    username: info.username,
                    password: info.password,
                    focusAreas: info.focusAreas,
                    challenges: info.challenges,
                    goalsLevel: info.goalsLevel
                }
            );
            console.log("res", res.data.entity);

            setInfo(res.data.entity);

            dispatch({ type: "LOGIN_PASS", payload: res.data.entity });
            setSuccessMessage("Profile updated successfully");
            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (err) {
            dispatch({ type: "SET_ERROR", payload: err.response?.data?.message || "Could not update profile" });
        }
    };

    const handleDelete = async (e) => {
        console.log("in handleDelete");

        e.preventDefault();
        try {
            const res = await api.delete(`/api/user/${user._id}`);
            dispatch({ type: "LOGOUT", payload: res.data });
            navigate('/');
        } catch (err) {
            dispatch({ type: "SET_ERROR", payload: err.response?.data?.message || "Could not delete profile" });
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Profile - Goals & Habits Management</title>
                <meta name="description" content="Manage your Goals & Habits profile and subscriptions." />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6">
                            {error && (
                                <div className="alert alert-danger text-center" role="alert">
                                    {error}
                                </div>
                            )}
                            {successMessage && (
                                <div className="alert alert-success text-center" role="alert">
                                    {successMessage}
                                </div>
                            )}
                            <div className="card rounded-3 p-0 mb-5">
                                <div className="card-header py-1">
                                    <h4 className="m-0 fw-normal text-center">Update Profile</h4>
                                </div>
                                <form className="card-body px-4 m-0">
                                    <div className="p-0 my-3 form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="text"
                                            placeholder={user.email}
                                            id="email"
                                            value={info.email}
                                            className="form-control"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="p-0 my-3 form-group">
                                        <label htmlFor="username">New User Name</label>
                                        <input
                                            onChange={handleChange}
                                            type="text"
                                            placeholder={user.username}
                                            id="username"
                                            value={info.username}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="p-0 my-3 form-group">
                                        <label htmlFor="password">New Password</label>
                                        <input
                                            onChange={handleChange}
                                            type="password"
                                            placeholder="********"
                                            id="password"
                                            value={info.password}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="p-0 my-3 form-group">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input
                                            onChange={handleChange}
                                            type="password"
                                            placeholder="********"
                                            id="confirmPassword"
                                            value={info.confirmPassword}
                                            className="form-control"
                                        />
                                        {passwordError && <div className="text-danger">{passwordError}</div>}
                                    </div>
                                    <div className="p-0 my-3 form-group">
                                        <h5 className="card-title mb-4">Edit Your Goal Focus Areas</h5>
                                        <div className="row g-3">
                                            {focusAreas.map((area) => {
                                                const isSelected = info.focusAreas.includes(area);
                                                return (
                                                    <div key={area} className="col-6 col-md-4">
                                                        <button
                                                            type="button"
                                                            className={`btn w-100 ${isSelected ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                                            style={{ fontSize: '.9rem' }}
                                                            onClick={() => handleFocusAreaChange(area)}
                                                        >
                                                            {area}
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {focusAreaError && <div className="text-danger">{focusAreaError}</div>}
                                    </div>
                                    <div className="p-0 my-3 form-group">
                                        <h5 className="card-title mb-4">Edit Your Common Challenges</h5>
                                        <div className="row g-3">
                                            {challengeAreas.map((challenge) => {
                                                const isSelected = info.challenges.includes(challenge);
                                                return (
                                                    <div key={challenge} className="col-6 col-md-4">
                                                        <button
                                                            type="button"
                                                            className={`btn w-100 h-100 ${isSelected ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                                            style={{ fontSize: '.9rem' }}
                                                            onClick={() => handleChallengeChange(challenge)}
                                                        >
                                                            {challenge}
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {challengeError && <div className="text-danger">{challengeError}</div>}
                                    </div>
                                    <div className="text-center">
                                        <div disabled={loading} onClick={handleUpdate} className="btn btn-primary-custom">
                                            <p className="d-flex justify-content-center my-auto">Update Profile</p>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="card rounded-3 p-0 mt-5 mb-2">
                                <div className="card-header">
                                    <h4 className="m-0 fw-normal text-center">Delete Profile</h4>
                                </div>
                                <div className="text-center p-4">
                                    <div onClick={handleShowModal} className="btn btn-primary-custom">
                                        <p className="d-flex justify-content-center my-auto">Delete</p>
                                    </div>
                                </div>

                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Deletion</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure you want to delete your profile? This action cannot be undone.
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="danger" onClick={handleDelete}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Profile;
