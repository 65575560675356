import { DragDropContext, Droppable } from "react-beautiful-dnd";
import GoalItem from './GoalItem';
import { Card, } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faBullseye, faChartLine } from '@fortawesome/free-solid-svg-icons';

const GoalWidget = ({ goals, totalGoals, updateGoalOrder, toggleGoalStatus, updateGoalDetail, updateGoalMeasure, updateGoalDeadline, removeGoal }) => {

    const onDragEnd = async (dragResult) => {
        const { source, destination } = dragResult;
        if (!destination || source.index === destination.index) return;
        const reorderedGoals = Array.from(goals);
        const [reorderedGoal] = reorderedGoals.splice(source.index, 1);
        reorderedGoals.splice(destination.index, 0, reorderedGoal);
        updateGoalOrder(reorderedGoals);
    };

    return (
        <div className="col-md-8 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold bg-pr-clr">
                    Goals
                </div>
                <div className="row">
                    <div className="col px-2 ms-4">

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="goals">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} className="row w-100">
                                        {goals.map((user2goal, index) => (
                                            <div key={user2goal._id} className="col-md-6 col-sm-12 p-1">
                                                <GoalItem
                                                    user2goal={user2goal}
                                                    index={index}
                                                    toggleGoalStatus={toggleGoalStatus}
                                                    updateGoalDetail={updateGoalDetail}
                                                    updateGoalMeasure={updateGoalMeasure}
                                                    updateGoalDeadline={updateGoalDeadline}
                                                    removeGoal={removeGoal}
                                                />
                                            </div>
                                        ))}
                                        {goals.length === 0 ? (
                                            <div className="col-md-6 col-sm-12 p-1">
                                                <div className="p-3 text-center">
                                                    <h3 className="fw-bold text-secondary">No goals yet!</h3>
                                                    <p>Select Goals to get started or use AI Plan to leverage AI</p>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div className="col-md-6 col-sm-12 p-1">
                                            <Card className="mb-1 shadow-sm dash-card-sm w-100 not-started-item">
                                                <Card.Body className="d-flex flex-row justify-content-center align-items-center h-100">
                                                    <div className="w-100">
                                                        <div className="row m-0">

                                                            <div className="col-6 d-flex justify-content-center">
                                                                <a href="/goals" className="btn btn-lg btn-primary-custom" title="Explore and manage your Goals">
                                                                    <FontAwesomeIcon icon={faBullseye} className="mr-2" />
                                                                    &nbsp;Goals
                                                                </a>
                                                            </div>
                                                            <div className="col-6 d-flex justify-content-center">
                                                                <a href="/ai" className="btn btn-lg btn-primary-custom" title="Leverage AI for goal planning">
                                                                    <FontAwesomeIcon icon={faBrain} className="mr-2" />
                                                                    &nbsp;AI Plan
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                    </div>
                </div>
                <div className="p-2 footer-border mt-auto bg-pr-clr">
                    {totalGoals} Goals
                </div>
            </div>
        </div>);
};

export default GoalWidget;
