import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import HabitItem from './HabitItem';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faAnglesRight, faChevronLeft, faAnglesLeft, faBrain, faChartLine, faBullseye } from "@fortawesome/free-solid-svg-icons";

import { todayStart, isToday, isFutureDate } from "../../utils/theDateHelper.js";
import { Card, } from 'react-bootstrap';

const HabitWidget = ({ habits, updateHabitOrder, totalHabits, removeHabit, toggleHabitComplete, toggleHabitFocus, toggleHabitFrog, updateHabitStart, updateHabitEnd, updateHabitDetail }) => {

    const onDragEnd = async (dragResult) => {
        const { source, destination } = dragResult;
        if (!destination || source.index === destination.index) return;
        const reorderedHabits = Array.from(habits);
        const [reorderedHabit] = reorderedHabits.splice(source.index, 1);
        reorderedHabits.splice(destination.index, 0, reorderedHabit);
        updateHabitOrder(reorderedHabits);
    };

    const [habitDay, setHabitDay] = useState(todayStart());
    const changeHabitDay = (increment) => {
        const tempDate = new Date(habitDay);
        tempDate.setDate(tempDate.getDate() + increment);
        setHabitDay(tempDate);
    };

    const resetHabitDay = () => setHabitDay(todayStart());

    const isEditable = (date) => {
        const today = todayStart();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return date >= yesterday && date <= today;
    };

    return (
        <div className="col-md-8 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold bg-sec-clr">
                    Daily Habits
                </div>
                <div className="flex-grow-1">
                    <div className="row p-2">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <div className="mx-3 text-center form-control border" style={{ width: '210px' }}>
                                {habitDay.toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            {[-7, -1, 1, 7].map((increment, idx) => (
                                <button
                                    key={increment}
                                    className={`btn btn-sm ${idx === 0 ? 'me-1' : idx === 1 ? 'mx-1' : 'ms-1'}`}
                                    style={{ backgroundColor: '#277da1', color: 'white' }}
                                    onClick={() => changeHabitDay(increment)}
                                    disabled={idx === 2 ? isToday(habitDay) : isFutureDate(increment, habitDay)}
                                >
                                    <FontAwesomeIcon icon={idx === 0 ? faAnglesLeft : idx === 1 ? faChevronLeft : idx === 2 ? faChevronRight : faAnglesRight} />
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-4 d-flex justify-content-center align-items-center">
                            <button
                                className="btn btn-sm"
                                style={{ backgroundColor: '#277da1', color: 'white' }}
                                onClick={() => resetHabitDay()}
                            >
                                Today
                            </button>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col px-2 ms-4">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="habits">
                                    {provided => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className="row w-100">
                                            {habits.map((user2habit, index) => (
                                                <div key={user2habit._id} className="col-md-6 col-sm-12 p-1">
                                                    <HabitItem
                                                        index={index}
                                                        user2habit={user2habit}
                                                        removeHabit={removeHabit}
                                                        toggleHabitComplete={toggleHabitComplete}
                                                        toggleHabitFocus={toggleHabitFocus}
                                                        toggleHabitFrog={toggleHabitFrog}
                                                        updateHabitStart={updateHabitStart}
                                                        updateHabitEnd={updateHabitEnd}
                                                        updateHabitDetail={updateHabitDetail}
                                                        habitDay={habitDay}
                                                        isEditable={isEditable}
                                                    />
                                                </div>
                                            ))}
                                            {habits.length === 0 ? (
                                                <div className="col-md-6 col-sm-12 p-1">
                                                    <div className="p-3 text-center">
                                                        <h3 className="fw-bold text-secondary">No habits yet!</h3>
                                                        <p>Select Habits to get started or use AI Plan to leverage AI</p>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className="col-md-6 col-sm-12 p-1">
                                                <Card className="mb-1 shadow-sm dash-card-lg w-100 not-started-item">
                                                    <Card.Body className="d-flex flex-row justify-content-center align-items-center h-100">
                                                        <div className="w-100">
                                                            <div className="row m-0">
                                                                <div className="col-6 d-flex justify-content-center">
                                                                    <a href="/habits" className="btn btn-lg btn-primary-custom" title="Explore and manage your habits">
                                                                        <FontAwesomeIcon icon={faChartLine} className="mr-2" />
                                                                        &nbsp;Habits
                                                                    </a>
                                                                </div>
                                                                <div className="col-6 d-flex justify-content-center">
                                                                    <a href="/ai" className="btn btn-lg btn-primary-custom" title="Leverage AI for habit planning">
                                                                        <FontAwesomeIcon icon={faBrain} className="mr-2" />
                                                                        &nbsp;AI&nbsp;Plan
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
                <div className="p-2 mt-auto bg-sec-clr">
                    {totalHabits} Daily Habits
                </div>
            </div>
        </div >
    );
};

export default HabitWidget;
